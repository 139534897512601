@import "../../index.scss";

a,
.button {
    text-decoration: none;
    background-color: $light;
    @include label;
    color: $dark;
    border: none;
    padding: $w1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &:hover {
        background-color: $lighter;
    }
    &.rounded {
        border-radius: 100%;
        width: 2.5rem;
        height: 2.5rem;
    }
    &.secondary {
        background-color: $dark;
        color: $light;
        outline: $light solid 2px;
        outline-offset: -2px;
        &:hover {
            background-color: $darker;
        }
    }
}

a {
    padding: 0;
    text-decoration: none;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    background-color: unset;
    &:hover {
        background-color: unset;
    }
    button {
        width: 100%;
    }
}
