//colors
$purple: #8470ff;
$lighter: #fff;
$light: #dbdbdb;
$dark: #141414;
$darker: #000;
$overlay: rgba($darker, 0.4);

//font-families
$FjallaOne: "Fjalla One";
$Merriweather: "Merriweather";

//spacing
$v1: 8px;
$w1: 16px;

//texts
@mixin heading {
  font-family: $FjallaOne;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  margin: 0;
}

@mixin label {
  font-family: $FjallaOne;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
}

@mixin paragraph {
  font-family: $Merriweather;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}

@mixin small {
  font-family: $Merriweather;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}

@mixin smaller {
  font-family: $Merriweather;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  margin: 0;
}

body {
  margin: 0;
  background-color: $dark;
  color: $light;
}

.App {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 128px;
  padding: 128px;
  margin: 0 auto;
  max-width: 1400px;
}

section {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 32px;
  .sub {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

p {
  @include paragraph();
  padding: 0;
  max-width: 45ch;
  &.small {
    @include small();
  }
  &.smaller {
    @include smaller();
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

@media screen and (max-width: 700px) {
  .App {
    padding: 32px;
    gap: 32px;
  }
  .buttons {
    flex-direction: column;
  }
}
