@import "../../index.scss";

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  box-sizing: border-box;
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    text-align: center;
    gap: 64px;
    margin: 128px 0;
    .logo {
      width: 96px;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}

@media screen and (max-width: 700px) {
  header {
    align-items: stretch;
    justify-content: center;
    .top {
      margin: 64px 0;
    }
    .buttons {
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
    }
  }
}
