body {
  margin: 0;
  background-color: #141414;
  color: #dbdbdb;
}

.App {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 128px;
  padding: 128px;
  margin: 0 auto;
  max-width: 1400px;
}

section {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 32px;
}
section .sub {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  max-width: 45ch;
}
p.small {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}
p.smaller {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  margin: 0;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

@media screen and (max-width: 700px) {
  .App {
    padding: 32px;
    gap: 32px;
  }
  .buttons {
    flex-direction: column;
  }
}
@keyframes fadeInMobile {
  from {
    transform: translate(-50%, -90%);
  }
  to {
    transform: translate(-50%, -100%);
  }
}
@keyframes fadeInDesktop {
  from {
    transform: translate(-50%, -40%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
.overlay {
  cursor: pointer;
}

.modal .content {
  position: fixed;
  z-index: 10;
  cursor: default;
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: #000;
  gap: 16px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  bottom: unset;
  max-width: unset;
  animation-name: fadeInDesktop;
  animation-duration: 0.5s;
}

@media only screen and (max-width: 700px) {
  .modal .content {
    transform: translate(-50%, -100%);
    top: 100%;
    width: 100vw;
    box-sizing: border-box;
    animation-name: fadeInMobile;
  }
}

