@import "../../index.scss";

@keyframes fadeInMobile {
  from {
    transform: translate(-50%, -90%);
  }
  to {
    transform: translate(-50%, -100%);
  }
}

@keyframes fadeInDesktop {
  from {
    transform: translate(-50%, -40%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}

.overlay {
  cursor: pointer;
}

.modal {
  .content {
    position: fixed;
    z-index: 10;
    cursor: default;
    display: flex;
    flex-direction: column;
    padding: 2 * $w1;
    background: $darker;
    gap: $w1;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    bottom: unset;
    max-width: unset;
    animation-name: fadeInDesktop;
    animation-duration: 0.5s;
  }
}

@media only screen and (max-width: 700px) {
  .modal {
    .content {
      transform: translate(-50%, -100%);
      top: 100%;
      width: 100vw;
      box-sizing: border-box;
      animation-name: fadeInMobile;
    }
  }
}
