.map {
  width: 100%;
  box-sizing: border-box;
  height: 23rem;
  z-index: 0;
}

.map > iframe {
  position: absolute;
  width: 100%;
  height: 23rem;
  z-index: 3;
  margin-left: -32px;
  border: none;
}

@media screen and (min-width: 700px) {
  .map > iframe {
    position: relative;
    margin: 0;
  }
}

