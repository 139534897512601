body {
  margin: 0;
  background-color: #141414;
  color: #dbdbdb;
}

.App {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 128px;
  padding: 128px;
  margin: 0 auto;
  max-width: 1400px;
}

section {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 32px;
}
section .sub {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  max-width: 45ch;
}
p.small {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}
p.smaller {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  margin: 0;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

@media screen and (max-width: 700px) {
  .App {
    padding: 32px;
    gap: 32px;
  }
  .buttons {
    flex-direction: column;
  }
}
.slider {
  display: flex;
  max-width: 512px;
}
.slider .swiper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.slider .swiper,
.slider .container-slider {
  width: 100%;
}
.slider .slide img {
  height: auto;
  width: 100%;
  min-height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.slider .slide-container {
  width: 80%;
}
.slider .footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
}
.slider .title {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
}
.slider .description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 20rem;
}
.slider .description > p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}
.slider .navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 7.5rem;
}

@media screen and (min-width: 700px) {
  .slider {
    width: 50%;
  }
}

