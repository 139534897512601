body {
  margin: 0;
  background-color: #141414;
  color: #dbdbdb;
}

.App {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 128px;
  padding: 128px;
  margin: 0 auto;
  max-width: 1400px;
}

section {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 32px;
}
section .sub {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  max-width: 45ch;
}
p.small {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}
p.smaller {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  margin: 0;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

@media screen and (max-width: 700px) {
  .App {
    padding: 32px;
    gap: 32px;
  }
  .buttons {
    flex-direction: column;
  }
}
footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
}

footer > img {
  width: 3rem;
}

.presbloc_footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
}

h1 {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  color: #dbdbdb;
}

#adresse {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: #dbdbdb;
}

#site {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: #8470ff;
  text-decoration-line: underline;
}

