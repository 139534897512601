body {
  margin: 0;
  background-color: #141414;
  color: #dbdbdb;
}

.App {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 128px;
  padding: 128px;
  margin: 0 auto;
  max-width: 1400px;
}

section {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 32px;
}
section .sub {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  max-width: 45ch;
}
p.small {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}
p.smaller {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  margin: 0;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

@media screen and (max-width: 700px) {
  .App {
    padding: 32px;
    gap: 32px;
  }
  .buttons {
    flex-direction: column;
  }
}
a,
.button {
  text-decoration: none;
  background-color: #dbdbdb;
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
  color: #141414;
  border: none;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
a:hover,
.button:hover {
  background-color: #fff;
}
a.rounded,
.button.rounded {
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
}
a.secondary,
.button.secondary {
  background-color: #141414;
  color: #dbdbdb;
  outline: #dbdbdb solid 2px;
  outline-offset: -2px;
}
a.secondary:hover,
.button.secondary:hover {
  background-color: #000;
}

a {
  padding: 0;
  text-decoration: none;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  background-color: unset;
}
a:hover {
  background-color: unset;
}
a button {
  width: 100%;
}

